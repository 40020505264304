import { useState, useEffect } from 'react'
import { FiMoon, FiSun } from 'react-icons/fi'
import { FaBars, FaTwitter, FaInstagram, FaFacebookF } from 'react-icons/fa'

const Navbar = () => {
  const [nav, setNav] = useState(false)
  const handleNav = () => {
    setNav(!nav)
  }

  const [theme, setTheme] = useState(null)

  useEffect(() => {
    if (localStorage.getItem('theme') === null) {
      if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        localStorage.setItem('theme', 'light')
      } else {
        localStorage.setItem('theme', 'dark')
      }
    }
  }, [])

  useEffect(() => {
    const html = document.querySelector('html')
    if (localStorage.getItem('theme') === 'dark') {
      html.classList.add('dark')
      setTheme('dark')
    } else {
      html.classList.remove('dark')
      setTheme('light')
    }
  }, [theme])

  const handleThemeSwitch = () => {
    if (localStorage.getItem('theme') === 'light') {
      setTheme('dark')
      localStorage.setItem('theme', 'dark')
    } else {
      setTheme('light')
      localStorage.setItem('theme', 'light')
    }
  }

  return (
    <div
      className={
        'absolute z-20 flex flex min-h-[50px] w-full items-center justify-between bg-slate-800/80 text-white'
      }
    >
      <div className="m-auto flex w-full max-w-[1280px] items-center justify-between">
        <ul className={'hidden px-4 sm:flex'}>
          <li>
            <a href="/" className={'mr-6 hover:text-green-600'}>
              Home
            </a>
          </li>
          <li>
            <a href="#about" className={'mr-6 hover:text-green-600'}>
              About
            </a>
          </li>
          <li>
            <a href="#services" className={'mr-6 hover:text-green-600'}>
              Services
            </a>
          </li>
          <li>
            <a href="#itservices" className={'mr-6 hover:text-green-600'}>
              IT Solutions
            </a>
          </li>
          <li>
            <a href="#contact" className={'hover:text-green-600'}>
              Contact
            </a>
          </li>
        </ul>
        <div className={'flex items-center justify-between px-4'}>
          <FaFacebookF className={'relative z-20 mx-4'} />
          <FaInstagram className={'relative z-20 mx-4'} />
          <FaTwitter className={'relative z-20 mx-4'} />
          <div onClick={handleThemeSwitch} className={'ml-4 cursor-pointer'}>
            {theme === 'light' ? (
              <FiMoon
                size={35}
                className={
                  'relative z-20 rounded-lg border-2 border-green-600 p-2 text-green-600'
                }
              />
            ) : (
              <FiSun
                size={35}
                className={
                  'relative z-20 rounded-lg border-2 border-amber-300 p-2 text-amber-300'
                }
              />
            )}
          </div>
        </div>
      </div>

      {/*Hamburger Icon*/}
      <div onClick={handleNav} className={'z-10 sm:hidden'}>
        <FaBars size={20} className={'mr-4 cursor-pointer'} />
      </div>
      {/*Mobile Menu*/}
      <div
        onClick={handleNav}
        className={
          nav
            ? 'absolute left-0 top-0 flex h-screen w-full flex-col overflow-y-hidden bg-slate-900/90 px-4 py-7 text-gray-300 duration-300 ease-linear md:hidden'
            : 'absolute top-0 left-[-100%] h-screen duration-300 ease-linear'
        }
      >
        <ul className={'h-full w-full pt-12 text-center'}>
          <li className={'py-8 text-2xl'}>
            <a href="/">Home</a>
          </li>
          <li className={'py-8 text-2xl'}>
            <a href="#about">About</a>
          </li>
          <li className={'py-8 text-2xl'}>
            <a href="#services">Services</a>
          </li>
          <li className={'py-8 text-2xl'}>
            <a href="#itservices">IT Solutions</a>
          </li>
          <li className={'py-8 text-2xl'}>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Navbar
