import { FiCheckCircle } from 'react-icons/fi'
const AboutSub = () => {
  return (
    <div className={' text-slate-500 dark:text-slate-400'}>
      <div className="relative m-auto max-w-[1280px] px-4 py-6">
        <div className="relative h-full w-full rounded  bg-white p-8 shadow-xl ring-1 ring-slate-900/5 dark:bg-slate-800">
          <h3
            className={
              'mb-4 text-xl font-bold uppercase text-green-600 dark:text-white'
            }
          >
            About Us
          </h3>
          <p className={'mb-4'}>
            For many years Armils has been providing engineering systems and
            solutions at all levels of complexity to its clients. We work only
            with trusted European brands to ensure that our clients only receive
            the highest quality solutions.For many years Armils has been
            providing engineering systems and solutions at all levels of
            complexity to its clients. We work only with trusted European brands
            to ensure that our clients only receive the highest quality
            solutions.
          </p>
          <p className={'mb-4'}>
            When executing construction projects, time is of essence. It is
            unpleasant to stop the whole process because of one missing detail.
            We understand how important time is to our clients when a part is
            needed because of a problem on site, and we are ready to find the
            needed part and get it to any place on the planet in the shortest
            time possible. We handle the whole process from finding the part to
            developing the logistical chain that brings it to where it needs to
            be.
          </p>
          <p className={'mb-4'}>
            Our management and product quality abides to the highest
            international standards. The best experts in the field, a modern
            business model and high quality project management ensures the
            quality of our services. Among Armils’ clients are some of Europe’s
            and Russia’s largest construction companies.
          </p>
          <h3
            className={
              'mb-4 text-xl font-bold uppercase text-green-600 dark:text-white'
            }
          >
            The benefits of "Armils"
          </h3>
          <div className="lg:flex lg:space-x-6">
            <div className={'lg:flex-1'}>
              <div className={'mb-4 flex items-center'}>
                <FiCheckCircle
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>
                  We only sell high quality products that are made in Europe and
                  are in line with the DDP requirements;
                </p>
              </div>
              <div className={'mb-4 flex items-center'}>
                <FiCheckCircle
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>
                  A wide network of partners and a fast system of delivery to
                  any country lets us ensure minimal downtime on your production
                  or construction;
                </p>
              </div>
              <div className={'mb-4 flex items-center'}>
                <FiCheckCircle
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>
                  Thanks to the wide network of partners, we can offer the
                  lowest price for equipment;
                </p>
              </div>
            </div>
            <div className={'lg:flex-1'}>
              <div className={'mb-4 flex items-center'}>
                <FiCheckCircle
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>
                  Reliability in every aspect is what we value in our company.
                  These aren’t mere words, we greatly value each of our clients;
                </p>
              </div>
              <div className={'mb-4 flex items-center'}>
                <FiCheckCircle
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>
                  Our experts have experience in installing engineering systems
                  in a wide variety of objects – starting with shopping malls to
                  facilities that are in use in oil refineries. This experience
                  allows us to adapt to any situation;
                </p>
              </div>
              <div className={'mb-4 flex items-center'}>
                <FiCheckCircle
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>
                  Experience in working on unique projects in terms of
                  technology and size together with the financial stability of
                  our company is what ensures a successful cooperation with our
                  clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutSub
