import React from 'react'
import { RiShareForwardLine } from 'react-icons/ri'

const Services = () => {
  return (
    <div id="services">
      <div
        className={
          'relative m-auto max-w-[1280px] px-4 py-6 text-slate-500 dark:text-slate-400 lg:mt-20'
        }
      >
        <div
          className={
            'relative h-full w-full rounded-lg bg-white p-8 shadow-xl ring-1 ring-slate-900/5 dark:bg-slate-800'
          }
        >
          <h3
            className={
              'mb-4 text-xl font-bold uppercase text-green-600 dark:text-white'
            }
          >
            Our services
          </h3>
          <p className={'mb-4'}>
            We offer purchase and delivery of elements for integrated
            engineering systems from Europe’s leading manufacturers:
          </p>
          <div className={'lg:flex lg:space-x-6'}>
            <div className={'lg:flex-1'}>
              <div className={'mb-4 flex items-center'}>
                <RiShareForwardLine
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>IT-solutions;</p>
              </div>
              <div className={'mb-4 flex items-center'}>
                <RiShareForwardLine
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>Heating;</p>
              </div>
              <div className={'mb-4 flex items-center'}>
                <RiShareForwardLine
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>Water supply;</p>
              </div>
              <div className={'mb-4 flex items-center'}>
                <RiShareForwardLine
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>Ventilation;</p>
              </div>
            </div>
            <div className={'lg:flex-1'}>
              <div className={'mb-4 flex items-center'}>
                <RiShareForwardLine
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>Air conditioning;</p>
              </div>
              <div className={'mb-4 flex items-center'}>
                <RiShareForwardLine
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>Power supply;</p>
              </div>
              <div className={'mb-4 flex items-center'}>
                <RiShareForwardLine
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>Video production;</p>
              </div>
            </div>
            <div className={'lg:flex-1'}>
              <div className={'mb-4 flex items-center'}>
                <RiShareForwardLine
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>Security systems;</p>
              </div>
              <div className={'mb-4 flex items-center'}>
                <RiShareForwardLine
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>TV systems;</p>
              </div>
              <div className={'mb-4 flex items-center'}>
                <RiShareForwardLine
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>Telephone communication;</p>
              </div>
            </div>
            <div className={'md:flex-1'}>
              <div className={'mb-4 flex items-center'}>
                <RiShareForwardLine
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>Audio and video systems;</p>
              </div>
              <div className={'mb-4 flex items-center'}>
                <RiShareForwardLine
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>Cold rooms;</p>
              </div>
              <div className={'mb-4 flex items-center'}>
                <RiShareForwardLine
                  size={30}
                  className={'mr-2 w-[50px] text-green-600 dark:text-white'}
                />
                <p className={'flex-1'}>Social media marketing.</p>
              </div>
            </div>
          </div>
          <h3
            className={'mb-4 text-xl font-bold text-green-600 dark:text-white'}
          >
            Special express delivery
          </h3>
          <p className={'mb-4'}>
            We can supply modern and reliable equipment for electricity,
            heating, water and sewage systems anywhere in the world. Speed is
            what sets us apart from the dozens of other companies specializing
            in engineering networks.
          </p>
          <p className={'mb-4'}>
            By contacting us today, you will find a solution to any problem that
            arises when establishing an effective system of engineering.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Services
