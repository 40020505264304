import certificate from '../assets/img/certificate.png'

const About = () => {
  return (
    <div id="about" className={'m-auto mt-[-100px] max-w-[1280px]'}>
      <div className={'lg:flex lg:items-center lg:justify-center'}>
        <div className={'relative p-4'}>
          <img
            src={certificate}
            alt="Armils Certificate"
            className={
              'right-0 m-auto rounded object-cover shadow-xl ring-1 ring-slate-900/5 lg:w-full'
            }
          />
        </div>
        <div className={'relative p-4'}>
          <div
            className={
              'relative h-full rounded bg-white bg-white p-8 text-slate-500 shadow-xl ring-1 ring-slate-900/5 dark:bg-slate-800 dark:text-slate-400'
            }
          >
            <p className={'mb-4'}>
              We are constantly expanding our range of products and IT-services,
              attracting new partners and producers, increasing inventory and
              ensuring security of supply. We work every day towards innovation
              to create the basis for reliable cooperation.
            </p>
            <p className={'mb-4'}>
              Trust us to take care of the reliability of your engineering
              systems!
            </p>
            <p className={'mb-4'}>
              Our company offers delivery of engineering equipment from leading
              European manufacturers for construction and production worldwide.
              The offered products meet all of the necessary quality
              requirements of the EU.
            </p>
            <h3
              className={
                'mb-4 text-xl font-bold text-green-600 dark:text-white'
              }
            >
              Why you need us?
            </h3>
            <p className={'mb-4'}>
              We specialize in fast delivery of equipment. Thanks to a wide
              network of suppliers we can find the necessary part in a matter of
              hours and deliver it to you. This minimizes stoppage of your
              construction or production.
            </p>
            <h3
              className={
                'mb-4 text-xl font-bold text-green-600 dark:text-white'
              }
            >
              Free consultation
            </h3>
            <p className={'mb-4'}>
              Our experts are ready to offer you professional advice on
              selecting of equipment that will fit with the required technical
              characteristics and features of the systems as a whole. Contact us
              right now, to ensure the reliability of your designs!
            </p>
            <h3 className={'text-xl font-bold text-green-600 dark:text-white'}>
              "Armils" - your reliable partner in shipments for engineering
              systems!
            </h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
