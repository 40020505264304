import React from 'react'
import contactImg from '../assets/img/contact.jpg'
import { MdOutlineMapsHomeWork } from 'react-icons/md'
import { AiOutlineMail } from 'react-icons/ai'
import { BsTelephone } from 'react-icons/bs'

const Contact = () => {
  return (
    <div
      id="contact"
      className={
        'relative m-auto max-w-[1280px] py-6 text-slate-500 dark:text-slate-400 lg:mt-20'
      }
    >
      <div className="relative mb-14 flex flex-col p-4 lg:mb-32 lg:flex-row xl:mb-64 xl:mt-20">
        <img
          src={contactImg}
          className="mb-8 hidden rounded-lg shadow-xl ring-1 ring-slate-900/5 lg:block"
          alt="Contact Information"
        />
        <div className="top-48 rounded-lg bg-white bg-white p-4 shadow-xl ring-1 ring-slate-900/5 dark:bg-slate-800 lg:absolute lg:right-0 lg:mr-4 lg:w-[640px] xl:w-[768px] xl:p-20">
          <h2 className="mt-10 mb-6 text-center font-sans text-xl font-bold uppercase text-green-600 dark:text-white lg:mt-0 lg:max-w-xl lg:text-left">
            Contact Information
          </h2>
          <div className="lg:max-w-md lg:text-left">
            <h3 className="text-xl font-medium uppercase">OÜ Armils</h3>
            <h4>Reg.Nr 12336320</h4>
            <h4 className="mb-8">VAT number EE101580500</h4>
            <div className={'mb-4 flex items-center'}>
              <MdOutlineMapsHomeWork
                size={30}
                className={'mr-2 w-[50px] text-green-600 dark:text-white'}
              />
              <p className="flex-1">Tornimäe 7-44, Tallinn 10145, Estonia</p>
            </div>
            <div className={'mb-4 flex items-center'}>
              <BsTelephone
                size={30}
                className={'mr-2 w-[50px] text-green-600 dark:text-white'}
              />
              <p className="flex-1">(+372) 58802874</p>
            </div>
            <div className={'mb-4 flex items-center'}>
              <AiOutlineMail
                size={30}
                className={'mr-2 w-[50px] text-green-600 dark:text-white'}
              />
              <p className="flex-1">am@armils.eu</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
