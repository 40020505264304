import videoBg from '../assets/videoBg.mp4'
const Hero = () => {
  return (
    <div className={'relative right-0 h-[90vh] w-full'}>
      <div className={'absolute top-0 left-0 z-10 h-full w-full'}></div>
      <video
        src={videoBg}
        autoPlay
        loop
        muted
        className={'absolute top-0 left-0 h-full w-full object-cover'}
      />
      <div>
        <div
          className={
            'absolute top-0 flex h-full w-full flex-col items-center justify-center text-center'
          }
        >
          <h1
            className={
              'outlineTitle text-3xl font-extrabold uppercase md:text-7xl'
            }
          >
            Engineering And Digital Solutions
          </h1>
          <h2
            className={'outlineTitle text-2xl font-bold uppercase md:text-6xl'}
          >
            of any complexity
          </h2>
        </div>
      </div>
    </div>
  )
}

export default Hero
