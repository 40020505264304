import React from 'react'
import itSolImg from '../assets/img/itsolutions.png'

const ItServices = () => {
  return (
    <div id="itservices">
      <div
        className={
          'relative m-auto max-w-[1280px] px-4 py-6 text-slate-500 dark:text-slate-400 lg:mt-20'
        }
      >
        <div
          className={
            'relative h-full w-full rounded-lg bg-white p-8 shadow-xl ring-1 ring-slate-900/5 dark:bg-slate-800'
          }
        >
          <h3
            className={
              'mb-4 text-xl font-bold uppercase text-green-600 dark:text-white'
            }
          >
            IT Solutions
          </h3>
          <p className={'mb-4'}>
            We’ve worked successfully with clients in vertical markets such as
            financial services, utilities, telcos, retail, insurance,
            healthcare, high tech, outsourcers, public sector, not for profit,
            education and B2B.
          </p>
          <p className={'mb-4'}>Today our key areas of focus are as follows:</p>
          <div className="grid gap-4 lg:grid-cols-3">
            <div>
              <h3 className={'font-bold text-green-600 dark:text-white'}>
                CONVERSATIONAL AI AND MESSAGING
              </h3>
              <p className={'mb-4'}>
                We focus on the needs of leading brands in the business to
                consumer, business to business and public sector.
              </p>
            </div>
            <div>
              <h3 className={'font-bold text-green-600 dark:text-white'}>
                WORKFORCE OPTIMISATION
              </h3>
              <p className={'mb-4'}>
                We provide the tools to help you drive customer satisfaction and
                improve performance while optimising resource management.
              </p>
            </div>
            <div>
              <h3 className={'font-bold text-green-600 dark:text-white'}>
                BESPOKE SERVICES
              </h3>
              <p className={'mb-4'}>
                Tailored services for organisations that have specialist or
                unique needs.
              </p>
            </div>
          </div>
          <h3
            className={'mb-4 text-xl font-bold text-green-600 dark:text-white'}
          >
            Solutions for our clients
          </h3>
          <div className="flex flex-wrap">
            <img
              src={itSolImg}
              className="mb-4 flex-1 lg:mr-4 lg:max-w-[350px]"
              alt="IT Solutions"
            />
            <p className={'mb-4 flex-1'}>
              Armils have a highly skilled and experienced team to help you
              successfully deploy leading edge Conversational AI and Messaging
              and Digital Engagement Solutions, having completed over 100
              projects in this area. Today more and more brands realise that
              they need to improve the way that they connect with their
              customers and move beyond the traditional channels of telephony
              and email. Consumers today use messaging in their daily lives to
              connect with friends and family and they want that ease of use
              when communicating and interacting with their service providers.
              We help you make this happen, from a people, process and
              technology perspective.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItServices
