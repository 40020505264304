import React from 'react'
import CookieConsent from 'react-cookie-consent'

const CookieBanner = () => {
  return (
    <div>
      <CookieConsent cookieName="armilsCookie">
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  )
}

export default CookieBanner
