import React from 'react'

const Map = () => {
  return (
    <div className={'relative'}>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        className="h-full h-40 w-full saturate-[.65] dark:grayscale md:h-60 lg:h-96"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2036.3874021788088!2d24.425734016036643!3d59.309787781652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692bc7130fca54d%3A0x5d2044569296c40c!2zTHVoYSA3LCBLZWlsYSwgNzY2MDggSGFyanUgbWFha29uZCwg0K3RgdGC0L7QvdC40Y8!5e0!3m2!1sru!2slv!4v1591557706676!5m2!1sru!2slv"
      ></iframe>
    </div>
  )
}

export default Map
