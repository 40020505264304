import React from 'react'

const Footer = () => {
  return (
    <div
      className={
        'min-h-[50px] bg-slate-900 py-4 text-center leading-8 text-slate-400'
      }
    >
      &copy; {new Date().getFullYear()} OÜ Armils. All rights reserved.
    </div>
  )
}

export default Footer
