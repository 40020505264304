import TopBar from './components/TopBar'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import About from './components/About'
import AboutSub from './components/AboutSub'
import Services from './components/Services'
import ItServices from './components/ItServices'
import Map from './components/Map'
import CookieBanner from './components/CookieBanner'
import Footer from './components/Footer'
import Contact from './components/Contact'
import ScrollToTop from './components/ScrollToTop'

function App() {
  return (
    <div className={'bg-slate-50 dark:bg-slate-900'}>
      <TopBar />
      <Navbar />
      <Hero />
      <About />
      <AboutSub />
      <Services />
      <ItServices />
      <Contact />
      <Map />
      <CookieBanner />
      <Footer />
      <ScrollToTop />
    </div>
  )
}

export default App
