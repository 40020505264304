import React from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { BsTelephone } from 'react-icons/bs'
import { TbTriangles } from 'react-icons/tb'

const TopBar = () => {
  return (
    <div className={'bg-white dark:bg-slate-800'}>
      <div
        className={
          'm-auto flex max-w-[1280px] items-center justify-between px-4 py-2'
        }
      >
        <div className={'ml-4 flex items-center sm:ml-0'}>
          <TbTriangles
            size={30}
            className={'mr-2 text-green-600 dark:text-white'}
          />
          <h1
            className={
              'text-xl font-bold uppercase text-green-600 dark:text-white'
            }
          >
            ARMILS
          </h1>
        </div>
        <div className="flex space-x-4">
          <div className={'hidden items-center md:flex'}>
            <BsTelephone
              size={20}
              className={'mr-2 text-green-600 dark:text-white'}
            />
            <p className={'text-sm text-green-600 dark:text-white'}>
              (+372) 58802874
            </p>
          </div>
          <div className={'hidden items-center md:flex'}>
            <AiOutlineMail
              size={20}
              className={'mr-2 text-green-600 dark:text-white'}
            />
            <p className={'text-sm text-green-600 dark:text-white'}>
              am@armils.eu
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopBar
